import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Use IFTTT as your Alarmserver",
  "path": "/Advanced_User/IFTTT_as_Alarmserver/",
  "dateChanged": "2019-10-23",
  "author": "Mike Polinowski",
  "excerpt": "If This Then That, also known as IFTTT is a free web-based service to create chains of simple conditional statements, called applets. Build your own applets to connect web services with your INSTAR IP Camera. Compatible with the camera models IN-8001 Full HD,  IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_IFTTT.png",
  "social": "/images/Search/AU_SearchThumb_IFTTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_IFTTT_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Use IFTTT as your Alarmserver' dateChanged='2019-10-23' author='Mike Polinowski' tag='INSTAR IP Camera' description='If This Then That, also known as IFTTT is a free web-based service to create chains of simple conditional statements, called applets. Build your own applets to connect web services with your INSTAR IP Camera. Compatible with the camera models IN-8001 Full HD,  IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_IFTTT.png' twitter='/images/Search/AU_SearchThumb_IFTTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/IFTTT_als_Alarmserver/' locationFR='/fr/Advanced_User/IFTTT_as_Alarmserver/' crumbLabel="IFTTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "ifttt-and-instar-full-hd-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#ifttt-and-instar-full-hd-cameras",
        "aria-label": "ifttt and instar full hd cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IFTTT and INSTAR Full HD Cameras`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#basic-setup"
        }}>{`Basic Setup`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#ifttt-setting-up-a-webhook"
            }}>{`IFTTT Setting up a Webhook`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#choosing-an-action-telegram-messenger"
            }}>{`Choosing an Action (Telegram Messenger)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#alarmserver-setup"
            }}>{`Alarmserver Setup`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "basic-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#basic-setup",
        "aria-label": "basic setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Basic Setup`}</h2>
    <p><em parentName="p">{`If This Then That`}</em>{`, also known as `}<a parentName="p" {...{
        "href": "https://ifttt.com/"
      }}>{`IFTTT`}</a>{` is a free web-based service to create chains of simple conditional statements, called applets. Build your own applets to connect web services with your INSTAR IP Camera.`}</p>
    <h3 {...{
      "id": "ifttt-setting-up-a-webhook",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ifttt-setting-up-a-webhook",
        "aria-label": "ifttt setting up a webhook permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IFTTT Setting up a Webhook`}</h3>
    <ol>
      <li parentName="ol">{`First you need to `}<a parentName="li" {...{
          "href": "https://ifttt.com/join"
        }}>{`create an account`}</a>{` on IFTTT and `}<a parentName="li" {...{
          "href": "https://ifttt.com/login?wp_=1"
        }}>{`login to your account`}</a>{`. Then go to the `}<strong parentName="li">{`Create`}</strong>{` and click on `}<strong parentName="li">{`IF + This`}</strong>{`:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/85e149a90a7ac8b07007a4a8eb5b3fd9/6bff2/IFTTT_for_INSTAR_Alarmserver_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABw0lEQVQY02WPwWvaABjFv0Sz2boqS13Iv6DQnRShy47tbl6K4EE6p2KcgR3GdljxILjLQAaDTR29CA2bbJDzGJPJDt4GBlxN7ArJwYrbIYlV6iT4lVjGDvsdfvB4vMODYDAYiURomt7YuOHxrAUCtzye6wBArHC73QDwIJUSRbHRaDSbzUql8uzgoFx+HovFgF3h9Xp9Pt81itqkN90uF/yFoigAqNVqiHhxMUdETdPi8fjOzi7HcUCSJIBjkiBcjgiGYQKBAMMwfr+fZVkA4m299ms8tkwLERVFoSiKZdlkMgn/s3V7a/vOdjQaDYVCdznuJk2L798Zk8loPHbGqrq2vk6SZDgchnw+n+d5vlDg9/dze3sPBSGTTmcymWw2y/N8LpdLJBKfPnz8fXo6Ugc4nQ6Pjx8Jwv1UqlgsAiKibTv++g3LL/7FFcvlEhEng5Ofn7/MByezfn/eV3H+56pzxgNVrR4evnry9PHuve+y/KPX0zTNsixJkur1eqfT0XV9dHa2WCwQcTqbtVotwzBs24arG+LR0cvXb9JCQdd1RVF6vd5wOJRlWZKkUqnUbrdN0zRMc3J+3u12q9WqYRiIeAnDSPaiPWXRKAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/85e149a90a7ac8b07007a4a8eb5b3fd9/e4706/IFTTT_for_INSTAR_Alarmserver_01.avif 230w", "/en/static/85e149a90a7ac8b07007a4a8eb5b3fd9/d1af7/IFTTT_for_INSTAR_Alarmserver_01.avif 460w", "/en/static/85e149a90a7ac8b07007a4a8eb5b3fd9/7f308/IFTTT_for_INSTAR_Alarmserver_01.avif 920w", "/en/static/85e149a90a7ac8b07007a4a8eb5b3fd9/b4975/IFTTT_for_INSTAR_Alarmserver_01.avif 957w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/85e149a90a7ac8b07007a4a8eb5b3fd9/a0b58/IFTTT_for_INSTAR_Alarmserver_01.webp 230w", "/en/static/85e149a90a7ac8b07007a4a8eb5b3fd9/bc10c/IFTTT_for_INSTAR_Alarmserver_01.webp 460w", "/en/static/85e149a90a7ac8b07007a4a8eb5b3fd9/966d8/IFTTT_for_INSTAR_Alarmserver_01.webp 920w", "/en/static/85e149a90a7ac8b07007a4a8eb5b3fd9/c15ec/IFTTT_for_INSTAR_Alarmserver_01.webp 957w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/85e149a90a7ac8b07007a4a8eb5b3fd9/81c8e/IFTTT_for_INSTAR_Alarmserver_01.png 230w", "/en/static/85e149a90a7ac8b07007a4a8eb5b3fd9/08a84/IFTTT_for_INSTAR_Alarmserver_01.png 460w", "/en/static/85e149a90a7ac8b07007a4a8eb5b3fd9/c0255/IFTTT_for_INSTAR_Alarmserver_01.png 920w", "/en/static/85e149a90a7ac8b07007a4a8eb5b3fd9/6bff2/IFTTT_for_INSTAR_Alarmserver_01.png 957w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/85e149a90a7ac8b07007a4a8eb5b3fd9/c0255/IFTTT_for_INSTAR_Alarmserver_01.png",
              "alt": "IFTTT für den INSTAR Alarmserver",
              "title": "IFTTT für den INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`We now want to set up a `}<a parentName="li" {...{
          "href": "https://ifttt.com/services/maker_webhooks"
        }}>{`webhook service`}</a>{` - which is basically just a web address that our INSTAR Full HD camera can contact in case of an alarm to trigger an IFTTT applet. Search for `}<em parentName="li">{`web`}</em>{` and select the `}<strong parentName="li">{`Webhooks Service`}</strong>{`:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2323f36e68f85a3feb9959af68106a5b/d7abb/IFTTT_for_INSTAR_Alarmserver_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABOElEQVQoz3WOMU7DMBSGcwjgCkzQIzAxoC4UpCIOwNArMMDEARgQYocODDAgVBSBogK9ACQYSqTiNClBSnFJk9LEfn4oiQoF0m+y3/P3+1dM09QNIwxDHEFTHMdhjNm23Wq1GGPJQkocAwAUxpjndTnn2UgiUssihOi6blFKyCMhxHXd1P0n4x9GLwDgOzGXRJYpiBjF8Suldv26Ty1AFEIAgBCCc56dgyAQQuT/LAAGfj98ocL3uUQEiRKkBEiiJXAeR9F4819yNnaHcq3uVhpu6aq9VAtWVVxRceMmicqp/XNJ109sOH343Hj7nD8xld33xTMsazi75/U2t+HuPpMmys2PeOrIXDhvz1Sbyj4rHOPcKRYOur2tHWk8TJSzXp0BX9c6pUunrNnFi2BZxWINK7fI82p/AfG57uu7PmzlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2323f36e68f85a3feb9959af68106a5b/e4706/IFTTT_for_INSTAR_Alarmserver_02.avif 230w", "/en/static/2323f36e68f85a3feb9959af68106a5b/d1af7/IFTTT_for_INSTAR_Alarmserver_02.avif 460w", "/en/static/2323f36e68f85a3feb9959af68106a5b/7f308/IFTTT_for_INSTAR_Alarmserver_02.avif 920w", "/en/static/2323f36e68f85a3feb9959af68106a5b/2fef5/IFTTT_for_INSTAR_Alarmserver_02.avif 959w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2323f36e68f85a3feb9959af68106a5b/a0b58/IFTTT_for_INSTAR_Alarmserver_02.webp 230w", "/en/static/2323f36e68f85a3feb9959af68106a5b/bc10c/IFTTT_for_INSTAR_Alarmserver_02.webp 460w", "/en/static/2323f36e68f85a3feb9959af68106a5b/966d8/IFTTT_for_INSTAR_Alarmserver_02.webp 920w", "/en/static/2323f36e68f85a3feb9959af68106a5b/1bae5/IFTTT_for_INSTAR_Alarmserver_02.webp 959w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2323f36e68f85a3feb9959af68106a5b/81c8e/IFTTT_for_INSTAR_Alarmserver_02.png 230w", "/en/static/2323f36e68f85a3feb9959af68106a5b/08a84/IFTTT_for_INSTAR_Alarmserver_02.png 460w", "/en/static/2323f36e68f85a3feb9959af68106a5b/c0255/IFTTT_for_INSTAR_Alarmserver_02.png 920w", "/en/static/2323f36e68f85a3feb9959af68106a5b/d7abb/IFTTT_for_INSTAR_Alarmserver_02.png 959w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2323f36e68f85a3feb9959af68106a5b/c0255/IFTTT_for_INSTAR_Alarmserver_02.png",
              "alt": "IFTTT für den INSTAR Alarmserver",
              "title": "IFTTT für den INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Then name the event - this will be part of the URL that we will later have to contact with with our camera to trigger the event:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6a84885a83239585b70fba12de599a11/d9199/IFTTT_for_INSTAR_Alarmserver_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.347826086956516%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABn0lEQVQoz43PzW4SURjG8RMlpnGlG9cmXoVbvQDjxtgLqDStRo1pNDF2aUw/dOFUTSWmcVG3fiVNoGBbxFIhmQJlBkopDhashdGCZYJMz/kb0IUYDT7b9/29H6LRaBimkc3lQc5vcewpk3GnWrayG4VMdt36uJU2MwWruJEvpA3Dtm1AKQVIKYXrurVarf5tD1TC5mKE4IfmbmnTXEuaSb1kFVJ6PLOW2CkVjXSqUq12YbpTb/HJYbtFxaXsqLLDV8UXyY5LU3V1/sKqE9mpeZc4OsOJZxyZoe8JfT4OTcvDj6XQlJaSoPZVN/49I1FerDfi1q7fqATze9HPRLZJ2kwbjOnthr/jziMMLtHvb10KOcOB+lCoOfyWoTDXljn1ivHEv/HPs88HEBoeHwd8iEeIKcQDPA8Rk0z0xvOIuxzUEPfbU9pYwzOFuPcf+FyAk8+5HHYH3rS8C+6FBde7uH81Io/Pckfvhc/MESpRK5p6NJxLxfV3i8G5l9TLt1cZXVE98GiMs35uxbi+wo33jCzLK+HvN2Ocfs1s7s/NPwDcWzqqDs1ufQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6a84885a83239585b70fba12de599a11/e4706/IFTTT_for_INSTAR_Alarmserver_03.avif 230w", "/en/static/6a84885a83239585b70fba12de599a11/d1af7/IFTTT_for_INSTAR_Alarmserver_03.avif 460w", "/en/static/6a84885a83239585b70fba12de599a11/7f308/IFTTT_for_INSTAR_Alarmserver_03.avif 920w", "/en/static/6a84885a83239585b70fba12de599a11/542bc/IFTTT_for_INSTAR_Alarmserver_03.avif 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6a84885a83239585b70fba12de599a11/a0b58/IFTTT_for_INSTAR_Alarmserver_03.webp 230w", "/en/static/6a84885a83239585b70fba12de599a11/bc10c/IFTTT_for_INSTAR_Alarmserver_03.webp 460w", "/en/static/6a84885a83239585b70fba12de599a11/966d8/IFTTT_for_INSTAR_Alarmserver_03.webp 920w", "/en/static/6a84885a83239585b70fba12de599a11/e46b2/IFTTT_for_INSTAR_Alarmserver_03.webp 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6a84885a83239585b70fba12de599a11/81c8e/IFTTT_for_INSTAR_Alarmserver_03.png 230w", "/en/static/6a84885a83239585b70fba12de599a11/08a84/IFTTT_for_INSTAR_Alarmserver_03.png 460w", "/en/static/6a84885a83239585b70fba12de599a11/c0255/IFTTT_for_INSTAR_Alarmserver_03.png 920w", "/en/static/6a84885a83239585b70fba12de599a11/d9199/IFTTT_for_INSTAR_Alarmserver_03.png 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6a84885a83239585b70fba12de599a11/c0255/IFTTT_for_INSTAR_Alarmserver_03.png",
              "alt": "IFTTT für den INSTAR Alarmserver",
              "title": "IFTTT für den INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`With the trigger set up we now need to assign an IFTTT service we want to trigger. To do this click on `}<strong parentName="li">{`that`}</strong>{`:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/93cfffeb24dcf0a409fef80e47b7a922/b97f6/IFTTT_for_INSTAR_Alarmserver_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABQklEQVQY03XOMUsCYRgH8PeEQHEw8J3sExQuId5eH0C4yQZr8RM0WoIY1hI4RnjQYpvymnT3vnf3GtcYcmARGNeh2Q1HGDQEVtJ1T6AQNfTb/g9//jwomUxijBdmwuFIJBwRBAH9L51Op1KpXG4zm91AoijGcRxjHI1GY7HFRGIpFAr9qQsCml3mo5IkZTKZYrFYKOwgQoiiKCqlhsZMnWpU4ZwbhsEYo5RSjemqqtfrBue6rjPGWq0WIaTZbDYaDfQ8Hg+HD5/Tj9cA7Cm8+AH4PswFAQB8Pbrvu6Wf+BuaTCb8wqwUtsvm0yqBg2s4lY9K5b12u62q6lW3e1jZX19eMTi3LGs0GjmO47ouAPi+jwDAdgaX7Ozk9m3tHGr3MOjfUKZVq1VZljudznGttpXP3/X7nufZtt3r9TzPm/0RfANb0ciNTdf8QwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/93cfffeb24dcf0a409fef80e47b7a922/e4706/IFTTT_for_INSTAR_Alarmserver_04.avif 230w", "/en/static/93cfffeb24dcf0a409fef80e47b7a922/d1af7/IFTTT_for_INSTAR_Alarmserver_04.avif 460w", "/en/static/93cfffeb24dcf0a409fef80e47b7a922/7f308/IFTTT_for_INSTAR_Alarmserver_04.avif 920w", "/en/static/93cfffeb24dcf0a409fef80e47b7a922/44a65/IFTTT_for_INSTAR_Alarmserver_04.avif 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/93cfffeb24dcf0a409fef80e47b7a922/a0b58/IFTTT_for_INSTAR_Alarmserver_04.webp 230w", "/en/static/93cfffeb24dcf0a409fef80e47b7a922/bc10c/IFTTT_for_INSTAR_Alarmserver_04.webp 460w", "/en/static/93cfffeb24dcf0a409fef80e47b7a922/966d8/IFTTT_for_INSTAR_Alarmserver_04.webp 920w", "/en/static/93cfffeb24dcf0a409fef80e47b7a922/cb8de/IFTTT_for_INSTAR_Alarmserver_04.webp 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/93cfffeb24dcf0a409fef80e47b7a922/81c8e/IFTTT_for_INSTAR_Alarmserver_04.png 230w", "/en/static/93cfffeb24dcf0a409fef80e47b7a922/08a84/IFTTT_for_INSTAR_Alarmserver_04.png 460w", "/en/static/93cfffeb24dcf0a409fef80e47b7a922/c0255/IFTTT_for_INSTAR_Alarmserver_04.png 920w", "/en/static/93cfffeb24dcf0a409fef80e47b7a922/b97f6/IFTTT_for_INSTAR_Alarmserver_04.png 958w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/93cfffeb24dcf0a409fef80e47b7a922/c0255/IFTTT_for_INSTAR_Alarmserver_04.png",
              "alt": "IFTTT für den INSTAR Alarmserver",
              "title": "IFTTT für den INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`As you can see from the list we have a large variety of services that we can now trigger with the webhook we created:`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/en/6f1f37a5786c8d0719d0bae4e0389a13/IFTTT_for_INSTAR_Alarmserver_05.gif",
        "alt": "IFTTT für den INSTAR Alarmserver"
      }}></img></p>
    <h3 {...{
      "id": "choosing-an-action-telegram-messenger",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#choosing-an-action-telegram-messenger",
        "aria-label": "choosing an action telegram messenger permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Choosing an Action (Telegram Messenger)`}</h3>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Let's use the `}<strong parentName="li">{`Telegram service`}</strong>{` - when the URL (webhook) for this applet is contacted we want to receive a text message on `}<a parentName="li" {...{
          "href": "https://telegram.org/"
        }}>{`Telegram Messenger`}</a>{`:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e3ce7d85593cb50da6ec623ed45fcb2a/6bff2/IFTTT_for_INSTAR_Alarmserver_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABfElEQVQoz5WPv07CUBTGLwMkLCVBFgILhUQSEiAh8RkY3FgkBmdfgRg2Exd9Ayd3B/8Nrg5GWnTBNiAIVKiV0kJpm7SF9l4DTRUFBn7TOfd833fOBTiOJ5NJDMO8Xq/H4/H5fG63GyzhcrkAAOFwOJvN5vP5UqkUiURAKpXKZDJ4FI/FosFgMB6Ph0Ihv9+/9ZdAIIBhWCKRyOVyhUKhWCym02nAcVy71er1eoIgDIdDQRAkSVIURXZQZNnuJEnieb7b7TIM02w2eZ4Huq7TFFWv1U3TRAipqkoSBFEmXinq5blSISsUTZMkWa2+oiUAhNCuoIMxR9M0URTH47FhGPoM458MQggWk2zdZDKZTqemBU2ITIgsJ332OL/ud/NPHkJIluXBYMAwHZZlLctyNNCeappmGMYK8+JJdl1m1evG6KoxehM1hJBlwRV/RmvYvXw/efo6uOscPXwihEy4iXnvpn1K9A/vP44fuY3N+7ednYva9jl9RvTXmb8BBd4OZGOyMjIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e3ce7d85593cb50da6ec623ed45fcb2a/e4706/IFTTT_for_INSTAR_Alarmserver_06.avif 230w", "/en/static/e3ce7d85593cb50da6ec623ed45fcb2a/d1af7/IFTTT_for_INSTAR_Alarmserver_06.avif 460w", "/en/static/e3ce7d85593cb50da6ec623ed45fcb2a/7f308/IFTTT_for_INSTAR_Alarmserver_06.avif 920w", "/en/static/e3ce7d85593cb50da6ec623ed45fcb2a/b4975/IFTTT_for_INSTAR_Alarmserver_06.avif 957w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e3ce7d85593cb50da6ec623ed45fcb2a/a0b58/IFTTT_for_INSTAR_Alarmserver_06.webp 230w", "/en/static/e3ce7d85593cb50da6ec623ed45fcb2a/bc10c/IFTTT_for_INSTAR_Alarmserver_06.webp 460w", "/en/static/e3ce7d85593cb50da6ec623ed45fcb2a/966d8/IFTTT_for_INSTAR_Alarmserver_06.webp 920w", "/en/static/e3ce7d85593cb50da6ec623ed45fcb2a/c15ec/IFTTT_for_INSTAR_Alarmserver_06.webp 957w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e3ce7d85593cb50da6ec623ed45fcb2a/81c8e/IFTTT_for_INSTAR_Alarmserver_06.png 230w", "/en/static/e3ce7d85593cb50da6ec623ed45fcb2a/08a84/IFTTT_for_INSTAR_Alarmserver_06.png 460w", "/en/static/e3ce7d85593cb50da6ec623ed45fcb2a/c0255/IFTTT_for_INSTAR_Alarmserver_06.png 920w", "/en/static/e3ce7d85593cb50da6ec623ed45fcb2a/6bff2/IFTTT_for_INSTAR_Alarmserver_06.png 957w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e3ce7d85593cb50da6ec623ed45fcb2a/c0255/IFTTT_for_INSTAR_Alarmserver_06.png",
              "alt": "IFTTT für den INSTAR Alarmserver",
              "title": "IFTTT für den INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Now you need to connect the IFTTT service to your Telegram account to allow the IFTTT bot to send you messages:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/24213d418dc115e31fd23d0a46455dcd/fbf08/IFTTT_for_INSTAR_Alarmserver_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACJElEQVQoz32SP0wTURzHOzjqpjHGRAJVaDk4Ugs0EpsYGRxAZ2Pi4OYAAzI4aAIxcRCGuhDjojFOJg5NjIOJUxOJRgZFtJR/RasF5Hrt0fb+9N7dx9w1RYuEb/LNu3vv9/u+7/e9F6jVaqwsL5NOL6IbJh6yJZPrr78zM7dBfn2NVCrF4MULdMkRpK4eOkISoXAXbcF2WtvO+GNbsMOfD7iui67rPh3H8QWLhs2TeYV3uTLKZp71HzluT9xHkro5NxCnLzaAJEcId3bvsrX1NMNDlwmwB94Ge6G4cOnWA0LBID3RGFH5LP09UeRIL52STFiS6ZZ7iQ1drTtssCFYqVRQi0VUtUhB2WbHtBm/N0XLyVO0yBHG28NMdkh09p8n1h8n0hfn2tgjboxP/XX4r6Ch61TLZWqWhWEYGNUqytYGn78ssLaU5tX7RQ5N5zic+MnbuUVWMmkWvqVZz2YPjqyWy6ytrpLJZJpqMhsGo2NPiY4+Z16xm9aaHPr0voXAmk4wePwER44d5crwMNVq1SvCFg4IC/PFYxZePsN2HBzhIITwLzWw150v6Di4Hz4yMzHJzZERHiYSWJbVKPJrNnWTinAanbvJ/ovMPrfswdvdo6ZpaIpK5beKrmiUtlVKmrbbtr9DbxQCYdu+iPdGvUh1ZZdlLc9sYYnZQobszpZn0PPo9wU4AI0YpmnWz4j6f7L0ibv5JHd+JXmz89WfE67ji/4BU7DnM3xWGZEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/24213d418dc115e31fd23d0a46455dcd/e4706/IFTTT_for_INSTAR_Alarmserver_07.avif 230w", "/en/static/24213d418dc115e31fd23d0a46455dcd/d1af7/IFTTT_for_INSTAR_Alarmserver_07.avif 460w", "/en/static/24213d418dc115e31fd23d0a46455dcd/7f308/IFTTT_for_INSTAR_Alarmserver_07.avif 920w", "/en/static/24213d418dc115e31fd23d0a46455dcd/4346e/IFTTT_for_INSTAR_Alarmserver_07.avif 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/24213d418dc115e31fd23d0a46455dcd/a0b58/IFTTT_for_INSTAR_Alarmserver_07.webp 230w", "/en/static/24213d418dc115e31fd23d0a46455dcd/bc10c/IFTTT_for_INSTAR_Alarmserver_07.webp 460w", "/en/static/24213d418dc115e31fd23d0a46455dcd/966d8/IFTTT_for_INSTAR_Alarmserver_07.webp 920w", "/en/static/24213d418dc115e31fd23d0a46455dcd/c76c7/IFTTT_for_INSTAR_Alarmserver_07.webp 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/24213d418dc115e31fd23d0a46455dcd/81c8e/IFTTT_for_INSTAR_Alarmserver_07.png 230w", "/en/static/24213d418dc115e31fd23d0a46455dcd/08a84/IFTTT_for_INSTAR_Alarmserver_07.png 460w", "/en/static/24213d418dc115e31fd23d0a46455dcd/c0255/IFTTT_for_INSTAR_Alarmserver_07.png 920w", "/en/static/24213d418dc115e31fd23d0a46455dcd/fbf08/IFTTT_for_INSTAR_Alarmserver_07.png 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/24213d418dc115e31fd23d0a46455dcd/c0255/IFTTT_for_INSTAR_Alarmserver_07.png",
              "alt": "IFTTT für den INSTAR Alarmserver",
              "title": "IFTTT für den INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Note`}</strong>{`: I experienced issues doing this with the IFTTT web interface on a Windows desktop with the Telegram desktop app. Switching to an Android phone solved those issues for me:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d49919964c55dc60b765b8c6ed261938/fbf08/IFTTT_for_INSTAR_Alarmserver_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7CAAAOwgEVKEqAAAACq0lEQVQoz12TS08TURSAZ6OgPC3ForS21EqhLRWm0FIQxAeoxERdGFcm/gAXLowrHwuNLnSHUUOEQI0xWtNG4zsxrozRRDTGqBsXxhjxUTrVAu3MtJ+ZOzyCN/nuuZl78+Xce85I9r6DuDt24NiwkfoNbUtweGXqve10d7XT29uDHNlEMBShRQ4TaGvHG2ilyaCljeagjC8oI/mie3H4u6ip91Bj91Jjb1yg1tlMla2BQ4ePcTN5l0RslOSNazxMxHmYuMWjZFzwOBnnSTLO/ds3kVZWr6HMYqe81kVFrYvKeVY3UGVbT2mZlf3nYlz+Btc+KMS/6Nz/BXd/mNz7acbEd3j6G6TqOg/VdesFhrDc6hJyI1ba3JSU1bLn1DCnP8DJVwpn3/xl6F2GsY8ZLrxVOPM6w4lXCkdfKJyfSCP5OrYSjPbjD2+loaULp79T4ApEcQe7WW5xEhuLYQ5dzJ+zcOQlvEvz3yggBSLbCUYHCHYN0LZpF63dOwn1DtLRt5tAZz8lFidXro6L4zN5TcQDdzJIx1MEYgpQRCsU0YugajpSU6hPSAxpS3SA5vYteFp7aJQ3C5avcjI8YgrVfJ4CkHnygOf7+rk4et3Mq1Aw89d1JLlnELlnF/7Idoxs/eFteENb8IW3CUosrgWhppkZ6mqer5/eU1BzSy4shF65F8/GbtFv9qYOgdMXYV1zGJe/k2XVjgXh7OwseVXlz3SWyakU2dwsuXwOVVUXhRVz7VJudZrVnV9bnVTZ3EilVoZHzKJMz8ygpNOoqb9oU1m0qWmUVJpcLr8oNPptkcUeNFi1xoO0wsrw6FyV9SKKNsOlH88EQ5NPmVQzYqtI0RQaf4NgXRM2d5C13pBYG9/q3AFKLQ5GxuceX9PJFnIk0xOCRHqClJZdIvwHF7Baf/YZf+gAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d49919964c55dc60b765b8c6ed261938/e4706/IFTTT_for_INSTAR_Alarmserver_08.avif 230w", "/en/static/d49919964c55dc60b765b8c6ed261938/d1af7/IFTTT_for_INSTAR_Alarmserver_08.avif 460w", "/en/static/d49919964c55dc60b765b8c6ed261938/7f308/IFTTT_for_INSTAR_Alarmserver_08.avif 920w", "/en/static/d49919964c55dc60b765b8c6ed261938/4346e/IFTTT_for_INSTAR_Alarmserver_08.avif 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d49919964c55dc60b765b8c6ed261938/a0b58/IFTTT_for_INSTAR_Alarmserver_08.webp 230w", "/en/static/d49919964c55dc60b765b8c6ed261938/bc10c/IFTTT_for_INSTAR_Alarmserver_08.webp 460w", "/en/static/d49919964c55dc60b765b8c6ed261938/966d8/IFTTT_for_INSTAR_Alarmserver_08.webp 920w", "/en/static/d49919964c55dc60b765b8c6ed261938/c76c7/IFTTT_for_INSTAR_Alarmserver_08.webp 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d49919964c55dc60b765b8c6ed261938/81c8e/IFTTT_for_INSTAR_Alarmserver_08.png 230w", "/en/static/d49919964c55dc60b765b8c6ed261938/08a84/IFTTT_for_INSTAR_Alarmserver_08.png 460w", "/en/static/d49919964c55dc60b765b8c6ed261938/c0255/IFTTT_for_INSTAR_Alarmserver_08.png 920w", "/en/static/d49919964c55dc60b765b8c6ed261938/fbf08/IFTTT_for_INSTAR_Alarmserver_08.png 962w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d49919964c55dc60b765b8c6ed261938/c0255/IFTTT_for_INSTAR_Alarmserver_08.png",
              "alt": "IFTTT für den INSTAR Alarmserver",
              "title": "IFTTT für den INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Once the IFTTT bot is we can choose that we want to receive a text message, when the applet is triggered:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/38e8826367af54231dc3386135ccf542/d7abb/IFTTT_for_INSTAR_Alarmserver_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACvUlEQVQ4y62T+0tTYRjH/RtMSii7WWTmTxFBF6nAsEwJ1B+CCkvoYv1cv5h0o3sRmemcS9Rm5rQMV94SCvOO4XbOznE353aynYlbI4wa7mzfeB9PMtP6qRdeXs77vM+H7/N8nxMXCoUgiiJsNjsURUEkClo1vB/5rW4Mj8vwTUrwBwLw+/2QJIlOtqLRKO3YFRcOh+lBMBjErKJgViUaHUGU9Pow4vJi9NMIRs08hDErTByHMZsDShQIR6KIqECCMyD+w4pVGcc+FPWi+8tP3OiX8WB4CneHfLg3NIXrfTJa7EHoLQHc7Pfi/pCM2wMyxa/1yTBN/aDciFo+KVRYmZwJxc8GkFbnQHq9DXsb7NhUJSC+1IxjxgkcfjWObbVW7FHvk7UClpVyqObm+skY88DwzHeEs3LwsOAydhtlZBvsyGpyIuflOHbobSjqknDUOIF9DXZkNztxwOBE+nM7UnQi6oXAQiCVTNQQrnS7sEojYMtTkVSwc3WFhYC5LS6s1ViQymI6Aak6ESvL+cXA+ZIBFPfKiH9kxvpKC9ZoLFinsSChlMPJNjcpXlHGEZTF2BvWjhp+KYWqKVrTNDINTuS1uEhR/msXDjY5cWvAhwvvJwmaGxNjpbe7vs0BozHA37PUOPYVJ9rcOP9OwukOD+3jb9yoGJ3GnUEfCts9ONcl4VSHB2c6JYr1fJ5Z6DIpVEsu+eglJ5mq7XVW6mNSOY+Ct25SxIzIaHRgs06kXiaW8fRHLS5ZBbK52lpjRabBQYlsfFhyUaeEI60T2KW3YX+jAxkvHNipt2GjVviLyyrwap9MitKqxXkVSRU8znZK1FdmEnM+RY0t6XIs8FKPFwmPORpalpxcKWB5GYfCNg/NX+ITntxlsQ1agd7WWv7hcpXZT86xfjEn8367POjDxQ+TONS8cALY244/XP4FugziQrGNCfoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/38e8826367af54231dc3386135ccf542/e4706/IFTTT_for_INSTAR_Alarmserver_09.avif 230w", "/en/static/38e8826367af54231dc3386135ccf542/d1af7/IFTTT_for_INSTAR_Alarmserver_09.avif 460w", "/en/static/38e8826367af54231dc3386135ccf542/7f308/IFTTT_for_INSTAR_Alarmserver_09.avif 920w", "/en/static/38e8826367af54231dc3386135ccf542/2fef5/IFTTT_for_INSTAR_Alarmserver_09.avif 959w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/38e8826367af54231dc3386135ccf542/a0b58/IFTTT_for_INSTAR_Alarmserver_09.webp 230w", "/en/static/38e8826367af54231dc3386135ccf542/bc10c/IFTTT_for_INSTAR_Alarmserver_09.webp 460w", "/en/static/38e8826367af54231dc3386135ccf542/966d8/IFTTT_for_INSTAR_Alarmserver_09.webp 920w", "/en/static/38e8826367af54231dc3386135ccf542/1bae5/IFTTT_for_INSTAR_Alarmserver_09.webp 959w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/38e8826367af54231dc3386135ccf542/81c8e/IFTTT_for_INSTAR_Alarmserver_09.png 230w", "/en/static/38e8826367af54231dc3386135ccf542/08a84/IFTTT_for_INSTAR_Alarmserver_09.png 460w", "/en/static/38e8826367af54231dc3386135ccf542/c0255/IFTTT_for_INSTAR_Alarmserver_09.png 920w", "/en/static/38e8826367af54231dc3386135ccf542/d7abb/IFTTT_for_INSTAR_Alarmserver_09.png 959w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/38e8826367af54231dc3386135ccf542/c0255/IFTTT_for_INSTAR_Alarmserver_09.png",
              "alt": "IFTTT für den INSTAR Alarmserver",
              "title": "IFTTT für den INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Now we can configure the subject and body text the email should have. Notice the words that are highlighted in white - these are variables that will be filled with data before the mail is sent. `}<strong parentName="li">{`EventName`}</strong>{` will be the name that you choose in step 4 above, `}<strong parentName="li">{`OccuredAt`}</strong>{` will be filled with a time stamp. The `}<strong parentName="li">{`Value 1-3`}</strong>{` below are variables that we can define in the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
        }}>{`Alarmserver setup in our camera's webUI`}</a>{` and are send them whenever a alarm is triggered:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0c8fab101f830285472bb3aff34d9098/d9199/IFTTT_for_INSTAR_Alarmserver_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB5klEQVQoz4XQW2vTYBgH8H0FQd38AoIMnIK7E7zyok78BgMRL7zbpxBhIB42h86yQ51FZi1eiIVaV500seeYpDGJ6XLqmjSnNgd7yPLmHdsUC9P6v35+z2kMx3EEQWzbhhDeRZuzKbnMCqX81xyC5PMFgqwhCEoQxFY2i6AoAAD+DgBgLAgC3/dBGEII07wdq7W9n13P0hRZ1Jqy2zYdy+g5tmMZuqGHh2V/MBzKHgj5zgDTeqTl18w+rnUJo09ZPmkO2M4eCIdrh3Bw2HJLdKdfsDNJ/nKcO7/GXoixF2Ps1Co9uUJfWmckZ3BgwmP419qCu4qpLU1j5BYlqSS/i/0QuVbHdLx7uQap9/6OjyZnRHcuLUTzwv3P7HJpN1pRnhYbUUzbIPXbKYEx+6PwtuydeVQ6/aB4cj536mFlYqk2vkiOLxInHuNnn1Oy44/CWcmdebNzJy3fTEnXk3wkUb+WqEcSXOQ1dyXO8p2RN2dEb70sm2rzC1p4++59rlBSDcsbBBDCpaqOa92RWPKSeEPEiwyBVZFtiSZEjlEUBUK4jOnf/oWP1v4ounMfxIUs9azY2PhuL1SMzI5VVy217c6jzf98+5PkTq3R0y+5yRX6XJSaeEJejVO3Nquzr8o3Nun6sZv3ATM3caqEQJl/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0c8fab101f830285472bb3aff34d9098/e4706/IFTTT_for_INSTAR_Alarmserver_10.avif 230w", "/en/static/0c8fab101f830285472bb3aff34d9098/d1af7/IFTTT_for_INSTAR_Alarmserver_10.avif 460w", "/en/static/0c8fab101f830285472bb3aff34d9098/7f308/IFTTT_for_INSTAR_Alarmserver_10.avif 920w", "/en/static/0c8fab101f830285472bb3aff34d9098/542bc/IFTTT_for_INSTAR_Alarmserver_10.avif 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0c8fab101f830285472bb3aff34d9098/a0b58/IFTTT_for_INSTAR_Alarmserver_10.webp 230w", "/en/static/0c8fab101f830285472bb3aff34d9098/bc10c/IFTTT_for_INSTAR_Alarmserver_10.webp 460w", "/en/static/0c8fab101f830285472bb3aff34d9098/966d8/IFTTT_for_INSTAR_Alarmserver_10.webp 920w", "/en/static/0c8fab101f830285472bb3aff34d9098/e46b2/IFTTT_for_INSTAR_Alarmserver_10.webp 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0c8fab101f830285472bb3aff34d9098/81c8e/IFTTT_for_INSTAR_Alarmserver_10.png 230w", "/en/static/0c8fab101f830285472bb3aff34d9098/08a84/IFTTT_for_INSTAR_Alarmserver_10.png 460w", "/en/static/0c8fab101f830285472bb3aff34d9098/c0255/IFTTT_for_INSTAR_Alarmserver_10.png 920w", "/en/static/0c8fab101f830285472bb3aff34d9098/d9199/IFTTT_for_INSTAR_Alarmserver_10.png 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0c8fab101f830285472bb3aff34d9098/c0255/IFTTT_for_INSTAR_Alarmserver_10.png",
              "alt": "IFTTT für den INSTAR Alarmserver",
              "title": "IFTTT für den INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "alarmserver-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarmserver-setup",
        "aria-label": "alarmserver setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarmserver Setup`}</h3>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`Now we have to configure the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
        }}>{`Alarmserver in our camera's webUI`}</a>{` so that the URL for our IFTTT Webhook receives a `}<strong parentName="li">{`POST`}</strong>{` message from our camera every time an alarm is triggered:`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`https://maker.ifttt.com/trigger/instar_alarmserver/with/key/API_KEY`}</code></pre></div>
    <p><strong parentName="p">{`Note`}</strong>{` that this is using the `}<strong parentName="p">{`Event Name`}</strong>{` that we set as name for our Webhook `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar_alarmserver`}</code>{`. To identify our IFTTT account we first need to find out our personal `}<strong parentName="p">{`API_KEY`}</strong>{` and add it at the end of the URL.`}</p>
    <p>{`To get access to our personal API key we first have to sign in to our IFTTT account and open the following page `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`https://ifttt.com/services/maker_webhooks/settings`}</code>{`. Copy the alpha-numeric key in the Account Info - e.g. if you find the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`URL: https://maker.ifttt.com/use/c3oL9WnAbz8Z08KumJWS`}</code>{`, then `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`c3oL9WnAbz8Z08KumJWS`}</code>{` is your API key:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9499a87e772e3087dd8fa338cdfafe1a/d7abb/IFTTT_for_INSTAR_Alarmserver_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAArElEQVQI11VOQQqDMBD0zy2l32gPwT+IRTyq59Jj1Vw8JKIHCSiCQUxKyG6JpaGdw8DuzO5MgF80TUMpraqyrqkxxu8BwFprARBRKTVNk5cC71FKSSmNMV3XRVGUJMk8z06w1vH9Ac/SGffx7xgQpJRaa0RkjKVpmhcFY3xd1zzLLoSQw5GcztcwvMXxp85vMmql7f5127ZlWRDx5aDHcWw574Xoh6HlXAjhk98fdtyVN18MyAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9499a87e772e3087dd8fa338cdfafe1a/e4706/IFTTT_for_INSTAR_Alarmserver_11.avif 230w", "/en/static/9499a87e772e3087dd8fa338cdfafe1a/d1af7/IFTTT_for_INSTAR_Alarmserver_11.avif 460w", "/en/static/9499a87e772e3087dd8fa338cdfafe1a/7f308/IFTTT_for_INSTAR_Alarmserver_11.avif 920w", "/en/static/9499a87e772e3087dd8fa338cdfafe1a/2fef5/IFTTT_for_INSTAR_Alarmserver_11.avif 959w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9499a87e772e3087dd8fa338cdfafe1a/a0b58/IFTTT_for_INSTAR_Alarmserver_11.webp 230w", "/en/static/9499a87e772e3087dd8fa338cdfafe1a/bc10c/IFTTT_for_INSTAR_Alarmserver_11.webp 460w", "/en/static/9499a87e772e3087dd8fa338cdfafe1a/966d8/IFTTT_for_INSTAR_Alarmserver_11.webp 920w", "/en/static/9499a87e772e3087dd8fa338cdfafe1a/1bae5/IFTTT_for_INSTAR_Alarmserver_11.webp 959w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9499a87e772e3087dd8fa338cdfafe1a/81c8e/IFTTT_for_INSTAR_Alarmserver_11.png 230w", "/en/static/9499a87e772e3087dd8fa338cdfafe1a/08a84/IFTTT_for_INSTAR_Alarmserver_11.png 460w", "/en/static/9499a87e772e3087dd8fa338cdfafe1a/c0255/IFTTT_for_INSTAR_Alarmserver_11.png 920w", "/en/static/9499a87e772e3087dd8fa338cdfafe1a/d7abb/IFTTT_for_INSTAR_Alarmserver_11.png 959w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9499a87e772e3087dd8fa338cdfafe1a/c0255/IFTTT_for_INSTAR_Alarmserver_11.png",
              "alt": "IFTTT für den INSTAR Alarmserver",
              "title": "IFTTT für den INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can now test our webhook and URL query parameter by copying the following URL (just replace your personal `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`API_Key`}</code>{`) and pasting it into the address bar of a web browser:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`https://maker.ifttt.com/trigger/instar_alarmserver/with/key/c3oL9WnAbz8Z08KumJWS?value1=Office&value2=Entrance_Left&value3=Alarm`}</code></pre></div>
    <p>{`You should get a reply that you triggered the `}<strong parentName="p">{`instar_alarmserver`}</strong>{` applet and shortly afterwards receive a message on Telegram:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/838f60c846ddbd22df861bcf44cd6efd/339e3/IFTTT_for_INSTAR_Alarmserver_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB3ElEQVQoz4XOXW/SUBjA8V4IZJujMPpCGfZQaEs5ZZT2lPLSAoWim26LJn4BpyYq8hJcCCtdlPgSR2a48cpoMvCTGpwXZsqW/C6enOf5Jwc7HY68kTsbHM9f9xbHw4v+m+/tzo9u/2I0np948xNv4XqL8enC9eau93M4+jr5+OH829n0/Mtshg1e9DtPXvWed/vPOp2jdveo3XvaHrwcjN9+9ibT8eTsb5cv7rtPy/n9FAuxkp8Avgjri7ABEgTIRIAE/gh7K8gs4bF/+fDtNQKsEQAL0SkmpcQFNc7nCRZG7vxBJrIUt7MVE7YYfhVsk+RQbd/afVxyHur2Qd7c06oPCvYBLDiMqOPRVJDiVsGCFCcoVWg4kt6Qiw40mtliK1tqCWo1li7g9LXxJsnlyneNxqFWva+Ye2m9ISE7jWxoOEAuB+nkDXG22MpV7glaXVDrKcUStXpGb4hqLSqgm75NgnBMCDP8egSEt9MJuUiycINK+qM8zvDXlL/jOORzFVhoZLSahGxYaNIiSrLQornQ8iKJ/weHX8aM/ojbqWSQLapVXjETGSPMayUg9ykQJMA6ATZWuE1yGDIsWbcgsiS1IqmVjGbKyBSRlUAW1JerK6BuKkU7X27mrd1fSPaJ3X6YIksAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/838f60c846ddbd22df861bcf44cd6efd/e4706/IFTTT_for_INSTAR_Alarmserver_12.avif 230w", "/en/static/838f60c846ddbd22df861bcf44cd6efd/d1af7/IFTTT_for_INSTAR_Alarmserver_12.avif 460w", "/en/static/838f60c846ddbd22df861bcf44cd6efd/7f308/IFTTT_for_INSTAR_Alarmserver_12.avif 920w", "/en/static/838f60c846ddbd22df861bcf44cd6efd/5d0a8/IFTTT_for_INSTAR_Alarmserver_12.avif 977w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/838f60c846ddbd22df861bcf44cd6efd/a0b58/IFTTT_for_INSTAR_Alarmserver_12.webp 230w", "/en/static/838f60c846ddbd22df861bcf44cd6efd/bc10c/IFTTT_for_INSTAR_Alarmserver_12.webp 460w", "/en/static/838f60c846ddbd22df861bcf44cd6efd/966d8/IFTTT_for_INSTAR_Alarmserver_12.webp 920w", "/en/static/838f60c846ddbd22df861bcf44cd6efd/56bc3/IFTTT_for_INSTAR_Alarmserver_12.webp 977w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/838f60c846ddbd22df861bcf44cd6efd/81c8e/IFTTT_for_INSTAR_Alarmserver_12.png 230w", "/en/static/838f60c846ddbd22df861bcf44cd6efd/08a84/IFTTT_for_INSTAR_Alarmserver_12.png 460w", "/en/static/838f60c846ddbd22df861bcf44cd6efd/c0255/IFTTT_for_INSTAR_Alarmserver_12.png 920w", "/en/static/838f60c846ddbd22df861bcf44cd6efd/339e3/IFTTT_for_INSTAR_Alarmserver_12.png 977w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/838f60c846ddbd22df861bcf44cd6efd/c0255/IFTTT_for_INSTAR_Alarmserver_12.png",
              "alt": "IFTTT für den INSTAR Alarmserver",
              "title": "IFTTT für den INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`Now we have to copy&paste all the parts from this URL into the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
        }}>{`Alarmserver configuration`}</a>{` in our camera's webUI:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9cf3c58df01db23ee015e4c822b1bf74/798d4/IFTTT_for_INSTAR_Alarmserver_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACHklEQVQoz12RzWsTQRjG929QUHqrImJBLK3kEIlBRSpUEMRCbW2rFAWhX8luZmc2KgZCKm1JNhWUXrz40VTRU1sv3oWiVy/2rEjbNF87X7vZeSU7tQcfXoZh4Pc+D88YmUzGxtjBKE1y9xfWx+fXJ55tjhQ2Rgr63Ph335xc/Pyg+GV86lHfubOnz/T0xWIGsm1MCMpYTwtLH7/XPnxrVLZqb77uv/5/qpWt6sYPH+WX4onY5d6+673nDWQTmzizKbNcdjmXOzVvZ7+1W/M6U6fVBqs26F6d7tW9P9UWC2B5pXjt9sDDUz3mkW7DQtjGZDZlFkslj7JGo1mvN4KgrQDa7VD6ftBuh6FSSnEZ7FJYWJ5PDl4YunF1cvRmBBMnlTZd1+WRpJRKKQCQUjJKpZRBJCklAJRXSuNTo2gezTyZMzAmCDtp03JdlzEmhNAkAPi+r184Z0Jwz/MAoPJpFVnW2L07/fF+I5t1LJuk0h1YSqnNGWO+7wNAqBN31ikhgx0PFp8v3O0aSxyNd3UfNxzHQZjMRbEjk46EEIfhD8UZ/+2pwmL+5LETl+LJ5JVkxxmTg9ie5zWbTR0PAJRSB85hCACt7Z+/+PbLVy8uDiSGJ4ZvjQ4ZhDgIO6m0VSyVOOeUUiGEhoUQjLEwDIMgYIy1oqXvVt9Oz0wjgshjJ/pn7JiWpdvWDWlYV6BhIQSlFADW1t5ns9l8Pp/L5f4CuwHamLC8rOwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9cf3c58df01db23ee015e4c822b1bf74/e4706/IFTTT_for_INSTAR_Alarmserver_13.avif 230w", "/en/static/9cf3c58df01db23ee015e4c822b1bf74/d1af7/IFTTT_for_INSTAR_Alarmserver_13.avif 460w", "/en/static/9cf3c58df01db23ee015e4c822b1bf74/7f308/IFTTT_for_INSTAR_Alarmserver_13.avif 920w", "/en/static/9cf3c58df01db23ee015e4c822b1bf74/aebcf/IFTTT_for_INSTAR_Alarmserver_13.avif 976w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9cf3c58df01db23ee015e4c822b1bf74/a0b58/IFTTT_for_INSTAR_Alarmserver_13.webp 230w", "/en/static/9cf3c58df01db23ee015e4c822b1bf74/bc10c/IFTTT_for_INSTAR_Alarmserver_13.webp 460w", "/en/static/9cf3c58df01db23ee015e4c822b1bf74/966d8/IFTTT_for_INSTAR_Alarmserver_13.webp 920w", "/en/static/9cf3c58df01db23ee015e4c822b1bf74/a8e2b/IFTTT_for_INSTAR_Alarmserver_13.webp 976w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9cf3c58df01db23ee015e4c822b1bf74/81c8e/IFTTT_for_INSTAR_Alarmserver_13.png 230w", "/en/static/9cf3c58df01db23ee015e4c822b1bf74/08a84/IFTTT_for_INSTAR_Alarmserver_13.png 460w", "/en/static/9cf3c58df01db23ee015e4c822b1bf74/c0255/IFTTT_for_INSTAR_Alarmserver_13.png 920w", "/en/static/9cf3c58df01db23ee015e4c822b1bf74/798d4/IFTTT_for_INSTAR_Alarmserver_13.png 976w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9cf3c58df01db23ee015e4c822b1bf74/c0255/IFTTT_for_INSTAR_Alarmserver_13.png",
              "alt": "IFTTT für den INSTAR Alarmserver",
              "title": "IFTTT für den INSTAR Alarmserver",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      